import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";

import styles from "./template.module.css";

function Template() {
  const [go, setGo] = useState();
  const [departament, setDepartment] = useState("");
  const [local, setLocal] = useState("");

  let { guid } = useParams();

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(true);

  const [locals, setLocals] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [done, setDone] = useState(0);

  useEffect(() => {
    async function fetchData() {
      await axios
        .get(
          `https://solmant.savoysignature.com:8001/communication/users/${guid}`
        )
        .then(async (response) => {
          console.log(response.data);
          setName(response.data.user.name);
          setCode(response.data.user.code);
          setDone(response.data.events[0].done);
          setDepartment(response.data.user.department);
          await axios
            .get(`https://solmant.savoysignature.com:8001/communication/locals/${guid}`)
            .then((response) => {
              setLocals(response.data);
              setLocal(response.data[0].id);
            });
          await axios
            .get(
              "https://solmant.savoysignature.com:8001/communication/departments"
            )
            .then((response) => {
              setDepartments(response.data);
            });
          setLoading(false);
        })
        .catch((err) => {
          // return (window.location = "/");
        });
    }
    fetchData();
  }, [guid]);

  async function submit() {
    const data = {
      local_id: parseInt(local),
      attend: go,
      department_id: parseInt(departament),
    };
    console.log(data);
    await axios
      .put(
        `https://solmant.savoysignature.com:8001/communication/users/${guid}/event/XMAS0574934`,
        data
      )
      .then((response) => {
        window.location.reload();
      });
  }

  return (
    <div className={styles.temp}>
      {!loading ? (
        <div className={styles.container}>
          <div className={styles.logo}>
            <img src={require("../../images/afavias.png")} alt="logo" />
          </div>
          <div className={styles.box}>
            <h3>Jantar Natal AFAVIAS</h3>
            <span>Data: 20 de dezembro | 18h30</span>
            <span>Local: Ballroom, Savoy Palace</span>
            <span>Contacto: 291 911 010 | comiteempresa@afa.pt</span>
            <div className={styles.separator}></div>
            <h2>{name}</h2>
            <p className={styles.department}>{departament}</p>
            <p className={styles.n}>
              <p>{code}</p>
              {done ? <div className={styles.submited}>Submetido</div> : ""}
            </p>
          </div>

          {done === false ? (
            <>
              <div className={styles.box}>
                <h4>Vai participar no convívio de Natal?</h4>
                <div className={styles.rowBtns}>
                  <div
                    onClick={() => setGo(1)}
                    className={go === 1 ? `${styles.selected}` : ""}
                  >
                    Sim
                  </div>
                  <div
                    onClick={() => setGo(0)}
                    className={go === 0 ? `${styles.selected}` : ""}
                  >
                    Não
                  </div>
                </div>
              </div>
              {go === 1 ? (
                <div className={styles.box}>
                  <h4>Precisa de transporte para o local do convívio?</h4>
                  <div className={styles.boxSelect}>
                    <select
                      onChange={(value) => setLocal(value.target.value)}
                      defaultValue={local}
                    >
                      {locals.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.prefix} {e.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className={styles.boxBtn}>
                <button disabled={go === undefined} onClick={submit}>
                  Confirmar
                </button>
              </div>
            </>
          ) : (
            ""
          )}
          <footer></footer>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Template;
