import React, { useEffect, useState } from "react";
import styles from "./template.module.css";
import axios from "axios";

export default function Talentos() {
  const [name, setName] = useState("");
  const [numero, setNumero] = useState("");
  const [contacto, setContacto] = useState("");
  const [participation, setParticipation] = useState("Individual");
  const [talent, setTalent] = useState("Comédia");
  const [outro, setOutro] = useState("");
  const [participants, setParticipants] = useState([{ name: "", number: "" }]);
  const [completed, setCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await axios
      .get("https://solmant.savoysignature.com:8001/communication/talent-info")
      .then((res) => setIsOpen(res.data["isOpen"]));
  }

  // Function to handle changes in participant fields
  const handleParticipantChange = (index, field, value) => {
    const updatedParticipants = [...participants];
    updatedParticipants[index][field] = value;
    setParticipants(updatedParticipants);
  };

  // Function to remove a participant
  const removeParticipant = (index) => {
    const updatedParticipants = participants.filter((_, i) => i !== index);
    setParticipants(updatedParticipants);
  };

  const filterEmptyParticipants = async () => {
    const filteredParticipants = participants.filter(
      (item) => item.name.trim() !== ""
    );
    setParticipants(filteredParticipants);
  };

  const submit = async () => {
    await filterEmptyParticipants();
    const data = {
      name: name,
      number: numero,
      contact: contacto,
      participation: participation,
      participants:
        participation === "Grupo"
          ? participants.filter((item) => item.name.trim() !== "")
          : [],
      talent: talent,
      outro: talent === "Outro" ? outro : null,
    };

    await axios
      .post(
        "https://solmant.savoysignature.com:8001/communication/talent-attendance/ESAF1569319",
        data
      )
      .then(() => setCompleted(true));
  };

  return (
    <div className={styles.temp}>
      <div className={styles.container}>
        {completed ? (
          <>
            <div className={styles.logo}>
              <img src={require("../../images/afavias.png")} alt="logo" />
            </div>

            <div className={styles.box}>
              <p style={{ textAlign: "center" }}>
                O seu pedido foi registado com sucesso
              </p>
              <div className={styles.submited}>Submetido</div>
            </div>
          </>
        ) : isOpen ? (
          <>
            <div className={styles.logo}>
              <img src={require("../../images/afavias.png")} alt="logo" />
            </div>
            <div className={styles.box}>
              <img
                className={styles.imageCartaz}
                src={require("../../images/image.jpg")}
                alt="cartaz"
              />
              <h4>Nome</h4>
              <div className={styles.boxSelect}>
                <input
                  type="text"
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                />
              </div>
              <h4>Número de colaborador</h4>
              <div className={styles.boxSelect}>
                <input
                  type="text"
                  value={numero}
                  onChange={(value) => setNumero(value.target.value)}
                />
              </div>
              <h4>Contacto</h4>
              <div className={styles.boxSelect}>
                <input
                  type="text"
                  value={contacto}
                  onChange={(value) => setContacto(value.target.value)}
                />
              </div>
              <h4>Participação</h4>
              <div className={styles.boxSelect}>
                <select
                  value={participation}
                  onChange={(value) => setParticipation(value.target.value)}
                >
                  <option value={"Individual"}>{"Individual"}</option>
                  <option value={"Grupo"}>{"Grupo"}</option>
                </select>
              </div>
              {participation === "Grupo" ? (
                <div className={styles.columnNames}>
                  {name && numero ? (
                    <div className={styles.rowInputs}>
                      <div className={styles.boxSelect}>
                        <input value={name} disabled type="text" />
                      </div>
                      <div className={styles.boxSelect}>
                        <input value={numero} disabled type="text" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {participants.map((item, index) => (
                    <div className={styles.rowInputs} key={index}>
                      <div className={styles.boxSelect}>
                        <input
                          value={item.name}
                          type="text"
                          onChange={(e) =>
                            handleParticipantChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className={styles.boxSelect}>
                        <input
                          value={item.number}
                          type="text"
                          onChange={(e) =>
                            handleParticipantChange(
                              index,
                              "number",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div
                        onClick={() => removeParticipant(index)}
                        className={styles.removeBtn}
                      >
                        Remover
                      </div>
                    </div>
                  ))}
                  <div
                    onClick={() => {
                      setParticipants([
                        ...participants,
                        { name: "", number: "" },
                      ]);
                    }}
                    className={styles.addBtn}
                  >
                    Adicionar participante
                  </div>
                </div>
              ) : null}
              <h4>Talento</h4>
              <div className={styles.boxSelect}>
                <select
                  value={talent}
                  onChange={(value) => setTalent(value.target.value)}
                >
                  <option value={"Comédia"}>{"Comédia"}</option>
                  <option value={"Dança"}>{"Dança"}</option>
                  <option value={"Música"}>{"Música"}</option>
                  <option value={"Teatro"}>{"Teatro"}</option>
                  <option value={"Outro"}>{"Outro"}</option>
                </select>
              </div>
              {talent === "Outro" ? (
                <div className={styles.boxSelect} style={{ marginTop: "14px" }}>
                  <input
                    value={outro}
                    type="text"
                    placeholder="Indique o talento"
                    onChange={(value) => setOutro(value.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
              <div className={styles.boxBtn}>
                <button
                  disabled={name === "" || numero === "" || contacto === ""}
                  onClick={submit}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.logo}>
              <img src={require("../../images/afavias.png")} alt="logo" />
            </div>
            <div className={styles.box}>
              <img
                className={styles.imageCartaz}
                src={require("../../images/image.jpg")}
                alt="cartaz"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
